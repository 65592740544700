import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Button } from '@rebass/emotion';
export const AST_EXPLORER_LINK = 'https://astexplorer.net/#/gist/2befce6edce1475eb4bbec001356b222/35997d3b44347daabad8dd1a107adc22dd873de2';
export const _frontmatter = {};
const layoutProps = {
  AST_EXPLORER_LINK,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <h1 {...{
      "id": "ast"
    }}>{`AST`}</h1>

    <Button as="a" href={AST_EXPLORER_LINK}>Explore the AST</Button>
    <p>{`MDX, the library, uses not one but two syntax trees.
The first, `}<a parentName="p" {...{
        "href": "#mdxast"
      }}>{`MDXAST`}</a>{`, represents markdown with embedded JSX, and is a superset of `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/mdast"
      }}>{`mdast`}</a>{`.
The second, `}<a parentName="p" {...{
        "href": "#mdxhast"
      }}>{`MDXHAST`}</a>{`, represent HTML with embedded JSX, and is a superset of `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/hast"
      }}>{`hast`}</a>{`.`}</p>
    <p>{`MDX includes a `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/specification"
      }}>{`specification`}</a>{` to define the syntax and transpilation.
It’s based on the `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark"
      }}>{`remark`}</a>{`/`}<a parentName="p" {...{
        "href": "https://github.com/rehypejs/rehype"
      }}>{`rehype`}</a>{`/`}<a parentName="p" {...{
        "href": "https://github.com/unifiedjs/unified"
      }}>{`unified`}</a>{` ecosystems to ensure robust
parsing and the ability to leverage plugins from within your MDX.
This can be leveraged by code formatters, linters, and implementations in other
languages created by the community.`}</p>
    <h2 {...{
      "id": "mdxast"
    }}>{`MDXAST`}</h2>
    <p>{`The majority of the MDXAST specification is defined by `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/mdast"
      }}>{`mdast`}</a>{`.
MDXAST is a superset with the following additional node types:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`jsx`}</inlineCode>{` (instead of `}<inlineCode parentName="li">{`html`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`comment`}</inlineCode>{` (instead of `}<inlineCode parentName="li">{`html`}</inlineCode>{` comments)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`import`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`export`}</inlineCode></li>
    </ul>
    <p>{`Any MDX document without those constructs is valid `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/mdast"
      }}>{`mdast`}</a>{`.`}</p>
    <p>{`For more information, see the `}<a parentName="p" {...{
        "href": "#mdxast"
      }}>{`MDXAST`}</a>{` specification.`}</p>
    <h2 {...{
      "id": "mdxhast"
    }}>{`MDXHAST`}</h2>
    <p>{`The majority of the MDXHAST specification is defined by `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/hast"
      }}>{`hast`}</a>{`.
MDXHAST includes all nodes defined by `}<a parentName="p" {...{
        "href": "#mdxast"
      }}>{`MDXAST`}</a>{`, except for `}<a parentName="p" {...{
        "href": "#comment"
      }}>{`Comment`}</a>{`, as
it’s defined by `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/hast"
      }}>{`hast`}</a>{` already.`}</p>
    <p>{`MDXAST defines the following additional node types:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`jsx`}</inlineCode>{` (instead of `}<inlineCode parentName="li">{`html`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`import`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`export`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`inlineCode`}</inlineCode></li>
    </ul>
    <p><inlineCode parentName="p">{`inlineCode`}</inlineCode>{` is there to accurately represent Markdown’s inline code.`}</p>
    <p>{`For more information, see the `}<a parentName="p" {...{
        "href": "#mdxhast"
      }}>{`MDXHAST`}</a>{` specification.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      